.appHeader {
    width: 100%;
    border-bottom: 1.5px solid #000;
    position: fixed;
    background: rgb(249 223 235 / 35%);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(5.5px);
    z-index: 99;
}

.contentContainer {
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.appHeader a {
    padding: 15px;
}

.linkStyle {
    text-decoration: none;
}

@media(min-width: 768px) {

    .contentContainer {
        justify-content: flex-end;
    }
}
