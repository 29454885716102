.mainContainer {
    margin: 0 auto;
    height: 100%;
    max-width: 1260px;
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.textContainer {
    padding: 0 2rem 15%;
}

.title {
    letter-spacing: -3px;
    font-size: 5em;
    margin: 0 0 0 -3px;
    line-height: 0.9;
}

.subTitle {
    font-size: 1.2em;
    margin: 1em 0;
}

.textContainer a {
    font-size: 0.9em;
}

@media(min-width: 576px) {
    .title {
        font-size: 6em;
        margin-left: -4px;
    }

    .subTitle {
        font-size: 1.2em;
        margin: 1em 25% 1em 0;
    }
}

@media(min-width: 768px) {

    .title {
        letter-spacing: -4px;
        font-size: 6.8em;
        margin-left: -5px;
    }

    .subTitle {
        font-size: 1.4em;
        margin: 1em 20% 1em 0;
    }

    .textContainer a {
        font-size: 1.2em;
    }
}

@media(min-width: 992px) {

    .title {
        font-size: 8.5em;
        letter-spacing: -5px;
    }

    .subTitle {
        font-size: 1.5em;
    }
}

@media(min-width: 1200px) {

    .title {
        letter-spacing: -7px;
        font-size: 10em;
        margin: 0 0 0 -8px;
    }

    .subTitle {
        font-size: 1.8em;
    }

    .textContainer a {
        font-size: 1.4em;
    }
}

@media(min-width: 576px) and (max-width: 992px) and (max-height: 400px) and (orientation: landscape) {
    .textContainer {
        padding: 0 1rem 1rem;
    }

    .title {
        font-size: 5em;
    }
}
