.projectContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 54px;
}

.textContainer {
    max-width: 400px;
    margin: 1rem;
}

.textContainer p {
    font-size: 0.8em;
    margin: 0;
    text-align: justify;
}

@media(min-width: 768px) {

    .projectContainer {
        padding-top: calc(102px - 1rem);
    }
}
